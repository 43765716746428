import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
// import LanguageForm from "@containers/language-translation/form";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import FaqArea from "@containers/faq/layout-03";
import LanguageTranslationIntro from "@containers/language-translation/intro";
import LanguageKeywords from "@containers/language-translation/keywords";
import LanguageExpertise from "@containers/language-translation/expertise";
import LanguageTranslationTips from "@containers/language-translation/translation-tips";
import LanguageVarieties from "@containers/language-translation/varieties";
import LanguageTranslators from "@containers/language-translation/translators";
import LanguageVoiceovers from "@containers/language-translation/voiceovers";
import LanguageSoftware from "@containers/language-translation/software";
import LanguageGames from "@containers/language-translation/games";
import LanguageServices from "@containers/language-translation/services";
import LanguageDocuments from "@containers/language-translation/documents";
import LanguageFacts from "@containers/language-translation/facts";
import HowWeWork from "@containers/global/how-we-work";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/common-quote-form";

const TurkishTranslationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Turkish Translation Services | Turkish Translation Company"
        description="Professional Turkish Translation Services. Our Turkish Translation Company has over 500 qualified Turkish Translators and Editors. Get a Free Quote!"
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["turkish-translation-header-section"]} />
        <QuoteForm />
        <FeatureGrid data={content["turkish-translation-features-body"]} />
        <PartnerArea data={content["turkish-translation-logo-body"]} />
        <LanguageTranslationIntro data={content["turkish-translation-intro"]} />
        <LanguageKeywords data={content["turkish-translation-keyword-body"]} />
        <LanguageTranslationIntro data={content["turkish-translation-services"]} />
        <LanguageExpertise
          data={content["turkish-translation-expertise-body"]}
        />
        <LanguageTranslationTips
          data={content["turkish-translation-tip-body"]}
        />
        <LanguageVarieties
          data={content["turkish-translation-varities-body"]}
        />
        <LanguageTranslators
          data={content["turkish-translation-translator-body"]}
        />
        <LanguageVoiceovers
          dataVoiceOver={content["turkish-translation-voiceover-body"]}
          dataWebsite={content["turkish-translation-website-body"]}
        />
        <LanguageSoftware data={content["turkish-translation-software-body"]} />
        <LanguageGames data={content["turkish-translation-games-body"]} />
        <LanguageServices
          intoEnglish={content["turkish-into-english-body"]}
          subtitlesData={content["turkish-translation-subtitle-body"]}
        />
        <LanguageDocuments
          documentData={content["turkish-translation-document-body"]}
          elearningData={content["turkish-translation-elearing-body"]}
        />
        <LanguageFacts data={content["turkish-translation-facts-body"]} />
        <HowWeWork data={content["howwework"]} />
        <FaqArea data={content["turkish-translation-faq-body"]} />
        <CaseStudy data={caseStudiesData} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query turkishTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "turkish-translation" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

TurkishTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default TurkishTranslationPage;
